import React, { useEffect,  useState } from "react";
import Layout from "../layout/Layout";
import { isUserLoggedIn } from "../utils/auth";
import { navigate } from "gatsby";
import useGetOrders from "../hooks/orders/useGetOrders";
import Loader from "../components/form/Loader";
import OrderListing from "../components/account-orders/OrderListing";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";
import { staticPage } from "../../config/staticPage";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";
import Search from "../components/common/Search";

const OrdersPage = ({ location }) => {
  const user = isUserLoggedIn();
  const [page, setPage] = useState(1);
  const { data, isLoading, isNextPage, onSearch } =
    useGetOrders({ page });
  const { details: representative } = useGetRepresentativesDetails();


  useEffect(() => {
    if (!user) {
      navigate("/login?redirect_to=/orders");
    }
  }, [user]);

  const handleSearch = (term) => {
    onSearch(term);
    setPage(1)
  };



  return (
    <>
      {user ? (
        <div className="page-wrapper account-page-wrapper">
          <div className="container">
            <Breadcrumbs location={location} />
          </div>

          <div className="container">
            <div className="account-content">
              <div className="row flex flex-wrap page-layout">
                <div className="col page-sidebar">
                  <div className="page-sidebar-inner">
                    <AccountPageNavigation path={"/orders"} />
                    {representative && (
                      <RepresentativeNavigation
                        representative={representative}
                      />
                    )}
                  </div>
                </div>
                <div className="col page-content">
                  <div className="page-content-inner">
                    <div className="page-heading-section">
                      <h1 className="page-title">Orders</h1>
                    </div>

                    <div className="order-search-header">
                      <Search
                        onSearch={handleSearch}
                        placeholder="Search for orders"
                      />
                    </div>

                    {isLoading ? (
                      <Loader />
                    ) : (
                      <OrderListing
                        setPage={setPage}
                        hasMoreData={isNextPage}
                        orders={data}
                        page={page}
                        isNextPage={isNextPage}
                        representative={representative || null}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;

  for (const obj of login_nav) {
    if (obj.name === "orders") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails?.title}</title>
      <meta name="description" content={loginDetails?.description} />
    </>
  );
};

OrdersPage.Layout = Layout;
export default OrdersPage;
