import React, { useState, useEffect } from "react";
import SearchCloseIcon from "../../assets/icon_js/IconCloseLight";

// Simple debounce hook
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Search = ({
  onSearch,
  defaultValue = "",
  placeholder = "Search",
  classNames = "",
  isDisable = false,
}) => {
  const [searchTerm, setSearchTerm] = useState(defaultValue);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleClear = () => {
    setSearchTerm("");
    onSearch("");
  };

  return (
    <div className="d-flex align-items-center position-relative site-search-block">
      <input
        type="text"
        className={`${classNames} form-input`}
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleSearch}
        disabled={isDisable}
      />
      {searchTerm && !isDisable && (
        <button className="site-search-button" onClick={handleClear}>
          <SearchCloseIcon />
        </button>
      )}
    </div>
  );
};

export default Search;
