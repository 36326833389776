import useSWR from "swr";
import Api from "../../services/Api";
import { orders } from "../../ApiEndpoints";
import { getCustomerId } from "../../utils/auth";
import { useState } from "react";

const initialFilters = {
  page: 1,
  limit: 10,
  filter: "", // Search filter
};

function useGetOrders({page}) {
  const [filters, setFilters] = useState(initialFilters);

  // Update the key function to include the filter in the API request
  const getKey = () => {
    const id = getCustomerId();
    const { filter } = filters;
    let url = `${orders}?customer_id=${id || 128}&page=${page}&limit=${10}`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    return url;
  };

  const { data, error } = useSWR(() => getKey(), getOrders);
  const isLoading = (!data && !error) || false;

  const onSearch = (term) => {
    setFilters((prevState) => ({
      ...prevState,
      filter: term,
      page: 1,
    }));
  };

  return {
    data: data?.data || [],
    pagination: data?.meta || {},
    isLoading,
    isNextPage: data?.next_page,
    filters,
    onSearch,
  };
}

async function getOrders(url) {
  const response = await Api.get(url);
  return response?.data || [];
}

export default useGetOrders;
